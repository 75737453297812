import { all, put, takeEvery } from "@redux-saga/core/effects";
import { setErrorStatus } from "../status/action";
import store from "../store";
import { checkAndAddRegData, updateRegData } from "../../Services/database";

export const actionTypes = {
  SET_PERMISSION_STATUS: "SET_PERMISSION_STATUS",
  SET_LOCATION_STATUS: "SET_LOCATION_STATUS",
  PUT_REGISTRATION_DATA: "PUT_REGISTRATION_DATA",
  ADD_REGISTRATION_DATA: "ADD_REGISTRATION_DATA",
  UPDATE_REGISTRATION_DATA: "UPDATE_REGISTRATION_DATA",
  CLEAR_PERMISSION_STATUS: "CLEAR_PERMISSION_STATUS",
};

function* setPermissionStatusWorker(action) {
  try {
    yield put({
      type: "SET_PERMISSIONS",
      payload: action.payload.permission,
    });
    if (action.payload.navigate) {
      action.payload.navigate("/login");
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

function* setLocationStatusWorker(action) {
  try {
    yield put({
      type: "SET_LOCATION_DATA",
      payload: action.payload.location,
    });
    if (action.payload.navigate) {
      action.payload.navigate(window.location.pathname);
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

function* putRegistrationData(action) {
  try {
    setAppLoading(true);

    const { regToken, androidVersion, deviceModel, deviceId } =
      action.payload.data;
    yield put({
      type: "SET_REG_DATA",
      payload: {
        ...(regToken ? { regToken: regToken } : {}),
        ...(androidVersion ? { androidVersion: androidVersion } : {}),
        ...(deviceModel ? { deviceModel: deviceModel } : {}),
        ...(deviceId ? { deviceId: deviceId } : {}),
      },
    });
    console.warn("added regData to redux");
    action.payload.navigate("/login");
    setAppLoading(false);
  } catch (error) {
    setAppLoading(false);
    setErrorStatus(error);
  }
}

function* addRegistrationData(action) {
  try {
    setAppLoading(true);
    const { regToken, androidVersion, deviceModel, deviceId } =
      action.payload.data;

    if (regToken && androidVersion && deviceModel && deviceId) {
      yield checkAndAddRegData(deviceId, {
        ...(regToken ? { registrationToken: regToken } : {}),
        lastLogin: +new Date(),
        ...(androidVersion
          ? { androidVersion: androidVersion.toString() }
          : {}),
        ...(deviceModel ? { deviceModel: deviceModel } : {}),
        citizen: {
          id: store.getState().profile.data.documentId,
          phoneNumber: store.getState().auth.data.phoneNumber,
          name: store.getState().profile.data.aadhaar.fullName,
        },
      });
      console.warn("added regData to db");
    } else {
      console.error("unable to add device data");
    }
    setAppLoading(false);
  } catch (error) {
    setAppLoading(false);
    setErrorStatus(error);
  }
}
function* updateRegistrationData(action) {
  try {
    setAppLoading(true);
    const { regToken, androidVersion, deviceModel, deviceId } =
      action.payload.data;

    if (deviceId) {
      yield updateRegData(deviceId, {
        ...(regToken ? { registrationToken: regToken } : {}),
        lastLogin: +new Date(),
        ...(androidVersion
          ? { androidVersion: androidVersion.toString() }
          : {}),
        ...(deviceModel ? { deviceModel: deviceModel } : {}),
        citizen: {
          id: store.getState().profile.data.documentId,
          phoneNumber: store.getState().auth.data.phoneNumber,
          name: store.getState().profile.data.aadhaar.fullName,
        },
      });
      console.warn("update regData to db");
    } else {
      console.error("unable to update device data");
    }
    setAppLoading(false);
  } catch (error) {
    setAppLoading(false);
    setErrorStatus(error);
  }
}

function* clearPermissionStatusWorker(action) {
  try {
    yield put({
      type: "RESET_APP_DATA",
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

export default function* appWatcher() {
  yield all([
    takeEvery("SET_PERMISSION_STATUS", setPermissionStatusWorker),
    takeEvery("SET_LOCATION_STATUS", setLocationStatusWorker),
    takeEvery("PUT_REGISTRATION_DATA", putRegistrationData),
    takeEvery("ADD_REGISTRATION_DATA", addRegistrationData),
    takeEvery("UPDATE_REGISTRATION_DATA", updateRegistrationData),
    takeEvery("CLEAR_PERMISSION_STATUS", clearPermissionStatusWorker),
  ]);
}

function* setAppLoading(bool) {
  yield put({
    type: "SET_APP_LOADING",
    payload: {
      loading: bool,
    },
  });
}
